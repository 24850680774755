{
  "coreCommon": {
    "backToPAge": "Zurück",
    "de": "Deutsch",
    "delete": "Löschen",
    "en": "Englisch",
    "imprint": "Impressum",
    "logout": "Abmelden",
    "no": "Nein",
    "pageInfo": "Seiteninformationen",
    "privacy": "Datenschutz",
    "profileImage": "Profilbild",
    "save": "Speichern",
    "settings": "Einstellungen",
    "termsOfUse": "Nutzungsbedingungen",
    "yes": "Ja"
  },
  "coreNavigation": {
    "dashboard": "Dashboard",
    "topics": "Aboverwaltung",
    "help": "Hilfe",
    "imprint": "Impressum",
    "images": "Bilder",
    "accounts": "Nutzer",
    "unlinkedPages": "Unverknüfte Seiten",
    "mandators": "Mandanten",
    "facebook": "Facebook",
    "statistics": "Statistiken"
  },
  "coreDashboard": {
    "activeSince": "Aktiv seit:",
    "coverage": "Reichweite:",
    "fans": "Fans",
    "person": "Person",
    "persons": "Personen",
    "goToFacebookFanSite": "Zur Facebook-Fanseite",
    "goToInstagramSite": "Zur Instagram-Seite",
    "goToLinkedInSite": "Zur LinkedIn-Seite",
    "instagramPosts": "Beiträge",
    "instagramPost": "Beitrag",
    "comment": "Kommentar",
    "comments": "Kommentare",
    "follower": "Follower",
    "subscribed": "Abonniert",
    "postingOverview": "Posting Übersicht",
    "networkContent": "Beiträge",
    "calendar": "Kalender",
    "AbsoluteReach": "größte absolute Reichweite",
    "RelativeReach": "größte relative Reichweite",
    "Impressions": "meiste Impressionen zuerst",
    "EngagementRate": "größte Engagement-Rate zuerst",
    "Shares": "meiste Shares zuerst",
    "Comments": "meiste Kommentare zuerst",
    "Likes": "meiste Likes zuerst",
    "Successful": "erfolgreich",
    "Individual": "individuell",
    "Notified": "benachrichtigt",
    "Pending": "steht aus",
    "Manual": "manuelle Veröffentlichung",
    "Failed": "fehlgeschlagen",
    "Deleted": "gelöscht",
    "statusOption": "Beiträge filtern",
    "toPost": "Zum Beitrag",
    "showCommentsAndAnswer": "Kommentare anzeigen und beantworten",
    "showComments": "Kommentare anzeigen",
    "hideComments": "Kommentare ausblenden",
    "impressions": "Impressionen",
    "of": "von",
    "topicDescriptionFirst": "Nachfolgend können Sie die Posting-Themenkanäle abonnieren. Wenn Sie Postings aus einem Thema immer direkt auf Ihre Socialmedia Seite gepostet haben möchten, wählen Sie bitte 'Automatisch veröffentlichen' aus.",
    "topicDescriptionSecond": "Es ist zu beachten, dass alle Themenkanäle für jeden Socialmedia Kanal separat eingestellt werden müssen.",
    "IndividualPost": "individueller Post",
    "ManualAbo": "manuelle Veröffentlichung",
    "AutomaticAbo": "automatische Veröffentlichung",
    "ErrorWhilePosting": "Veröffentlichung fehlgeschlagen",
    "AwaitingUserConfirmation": "Wartet auf Bestätigung",
    "Unknown": "Template",
    "templates": "Template",
    "posting": "Beitrag verfassen",
    "takeTemplate": "Beitragsvorlagen",
    "postDeleted": "Beitrag wurde bereits gelöscht!",
    "noConnection": "Sie haben noch keine Seite verbunden.",
    "connectNow": "Jetzt verbinden!"
  },
  "coreCalendar": {
    "confirmPost": "Beitragsveröffentlichung bestätigen",
    "retry": "Erneut veröffentlichen",
    "deferredPostPending": "ausstehend",
    "deferredPostStatusScheduled": "Veröffentlichung geplant",
    "deferredPostStatusAwaitingUserConfirmation": "Warten auf Freigabe",
    "deferredPostStatusAwaitingUserConfirmationLower": "warten auf Freigabe",
    "deferredPostStatusQueued": "Veröffentlichung geplant",
    "deferredPostStatusProcessing": "Veröffentlichung läuft",
    "deferredPostStatusRetryScheduled": "erneute Veröffentichung geplant",
    "deferredPostStatusTokenInvalid": "Token ungültig",
    "deferredPostStatusErrorWhilePosting": "Fehler beim Posten",
    "monday": "Montag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "thursday": "Donnerstag",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag",
    "january": "Januar",
    "february": "Februar",
    "march": "März",
    "april": "April",
    "may": "Mai",
    "june": "Juni",
    "july": "Juli",
    "august": "August",
    "september": "September",
    "october": "Oktober",
    "november": "November",
    "december": "Dezember",
    "drop": "Hier ablegen",
    "noPosts": "In dieser Woche sind keine Beiträge geplant.",
    "today": "Heute",
    "onlyMyPostings": "Zeige eigene Postings",
    "topics": "Themenkanäle",
    "choose": "Bitte wählen",
    "networks": "Netzwerke",
    "postType": "Postingtyp",
    "LINK": "Linkbeiträge",
    "MESSAGE": "Nachrichtbeiträge",
    "PHOTO": "Fotobeiträge",
    "VIDEO": "Videobeiträge",
    "editTemplate": "Template bearbeiten",
    "subscribedAbo": "Aktuelles Abonnement",
    "automatic": "Automatisch",
    "individually": "Individuell",
    "individualHint": "individuelle Freigabe ab",
    "deletePost": "Beitrag löschen",
    "ownPosting": "Eigener Beitrag",
    "deleteTemplate": "Template löschen",
    "onlyTemplate": "Nur Template entfernen",
    "displayFilter": "Anzeigefilter",
    "subscribedPosts": "Abonnierte Beiträge",
    "ownPosts": "Eigene Beiträge",
    "morePosts": "Weitere Beitragsvorschläge",
    "takeTemplate": "Beitrag verwenden",
    "reallyDelete": "Wirklich löschen?",
    "templateAndPosts": "Template und alle Posts löschen",
    "deleteTemplateWarning": "Soll das aktuelle Template inklusive aller Postings und Statistiken endgültig gelöscht werden? Dies kann nicht rückgängig gemacht werden.\n(Instagram Beiträge können aktuell nicht gelöscht werden.)",
    "autoPublications": "Automatische Veröffentlichungen",
    "individualPublications": "Individuelle Veröffentlichungen",
    "publications": "Veröffentlichungen",
    "subscribableTopic": "abonnierbares Posting",
    "category": "Postingvorlage"
  },
  "coreSettings": {
    "username": "Benutzername",
    "firstname": "Vorname",
    "lastname": "Nachname",
    "email": "E-Mail-Adresse",
    "language": "Sprache",
    "currentPassword": "Aktuelles Passwort",
    "newPassword": "Neues Passwort",
    "repeatPassword": "Passwort wiederholen",
    "createdAt": "Registriert am:",
    "changePassword": "Passwort ändern",
    "descPass": "Das Kennwort muss 6 bis 20 Zeichen lang sein, aus Groß- und Kleinbuchstaben sowie Ziffern und wenigstens einem Sonderzeichen (@#$%_-) bestehen.",
    "noConnection": "Sie haben noch keine Verbindung hergestellt.",
    "fbConnectionDesc": "Sie haben die Verbindung zu Facebook erfolgreich hergestellt. Diese kann jederzeit getrennt werden",
    "fbInvalidConnectionDesc": "Die Verbindung mit Facebook ist ungültig. Trennen Sie die Verbindung und verbinden Sie sich anschließend erneut.",
    "igInvalidConnectionDesc": "Die Verbindung mit Instagram ist ungültig. Trennen Sie die Verbindung und verbinden Sie sich anschließend erneut.",
    "instagramConnectionDesc": "Sie haben die Verbindung zu Instagram erfolgreich hergestellt. Diese kann jederzeit getrennt werden",
    "noConnectedPages": "- keine -",
    "googleConnectionDesc": "Sie haben erfolgreich die Verbindung zu GoogleMyBusiness hergestellt. Sie können diese bei Bedarf wieder trennen und neu verbinden.",
    "fbConnectedPages": "Verbundene Facebook Seite",
    "fbAvailablePages": "Verfügbare Facebook Seiten",
    "instagramConnectedPages": "Verbundene Instagram Seite",
    "instagramAvailablePages": "Verfügbare Instagram Seiten",
    "disconnectStart": "Verbindung mit",
    "disconnectEnd": "trennen",
    "connectEnd": "herstellen",
    "siteOwner": "Seiteninhaber",
    "loggedInAs": "angemeldet als",
    "userData":  "Nutzereinstellungen",
    "connect":  "Verbindungen",
    "connectionDisabled": "Profil kann aktuell nicht verbunden werden, bitte wenden Sie sich an Ihren Administrator.",
    "externalLinkLabel": "Zur Profilpflege"
  },
  "coreHelp": {
    "help": "Hilfebereich",
    "postHeader": "Beitragstitel",
    "createHelpPost": "Hilfepost erstellen",
    "postType": "Art des Hilfeposts",
    "postTypeText": "Text mit Bild",
    "postTypeYTVideo": "Youtube Video",
    "postTypeVideo": "Video",
    "missingInputError": "Nicht ausgefüllt",
    "postDate": "Datum",
    "text": "Text",
    "linkDesc": "Link Beschreibung",
    "linkUrl": "Link URL",
    "videoUrl": "Video URL",
    "saveChanges": "Änderungen speichern"
  },
  "coreCommitment": {
    "continue": "AKZEPTIEREN",
    "checkBoxLabel1": "Ich habe die ",
    "checkBoxLabel2": " zur Kenntnis genommen",
    "checkBoxLabelTermsOfUse": "Nutzungsbedingungen",
    "commitmentText1": "Dies ist eine notwendige Änderung, da nun die Möglichkeit besteht Instagram ebenfalls zu verknüpfen. Um Professocial weiterhin nutzen zu können, lesen Sie die ",
    "commitmentText2": " und akzeptieren Sie diese.",
    "headline": "Herzlich Willkommen im ERGO ProfesSocial-Baukasten"
  },
  "coreImages": {
    "titleImages": "Titelbilder",
    "profileImages": "Profilbilder",
    "mediaGallery": "Mediendatenbank",
    "titleImagesDesc": "Hier verwalten Sie die Facebook Titelbilder für Ihre Agenturen. Dabei handelt es sich um reine Vorlagen, welche nachträglich keine Verbindung zu den tatsächlich installierten Bildern haben. So ist es nicht möglich, Bilder zentral auszutauschen oder zu entfernen.",
    "titleImagesDesc2": "Das Titelbild einer Facebook-Page:",
    "titleImagesDesc3": "- wird auf Computern mit einer Abmessung von 820 x 312 Pixel und auf Smartphones mit einer Abmessung von 640 x 360 Pixel dargestellt.",
    "titleImagesDesc4": "- lädt am schnellsten als sRGB-JPG-Datei mit einer Abmessung von 851 x 315 Pixel und einer Dateigröße unter 100 KB.",
    "titleImagesDesc5": "Bei Profil- und Titelbildern mit einem Logo oder Text kann durch die Verwendung einer PNG-Datei möglicherweise die Qualität verbessert werden.",
    "profileImagesDesc": "Hier verwalten Sie die Facebook Profil-Bilder für Ihre Agenturen.",
    "profileImagesDesc1": "Dabei handelt es sich um reine Vorlagen, welche nachträglich keine Verbindung zu den tatsächlich installierten Bildern haben. So ist es nicht möglich, Bilder zentral auszutauschen oder zu entfernen.",
    "profileImagesDesc2": "Das Profilbild einer Facebook-Seite wird auf Computern standardmäßig mit einer Abmessung von 170 x 170 Pixeln, auf Smartphones mit 128 x 128 Pixeln und auf den meisten Handys mit 36 x 36 Pixeln dargestellt. In der Vorschau und Detailansicht wird eine größere Auflösung verwendet.\nFacebook passt die Größe deiner Fotos automatisch an und formatiert sie, nachdem sie hochgeladen wurden. Damit die Fotos in der bestmöglichen Qualität angezeigt werden, sollte eine Auflösung von 960 x 960 Pixeln verwendet werden.",
    "mediaGalleryDesc": "Hier werden Bilder & Videos hinterlegt, welche die Vermittler jederzeit auf ihrer Faceboook-Page verwenden & veröffentlichen können. \nMit Hilfe von Tags können jedem Bild/Video individuelle oder gemeinsame Schlagworte zugeordnet werden.",
    "deleteFile": "Datei Löschen",
    "deleteFileWarning": "Wollen sie die Datei wirklich Löschen?"
  },
  "commonImage": {
    "uploadImageHint": "Laden Sie ein neues Bild/Video hoch oder wählen sie ein Bild/Video aus der Mediendatenbank.",
    "getCroppedImage": "Bereich auswählen",
    "uploadNewImage": "Neues Bild hochladen",
    "uploadNewFile": "Datei hochladen",
    "chooseNewFile": "Neue Datei wählen",
    "newFile": "Neue Datei",
    "file": "Die Datei",
    "visible":  "sichtbar",
    "visibleFrom": "sichtbar von",
    "visibleUntil": "sichtbar bis",
    "temporaryMediaFile": "Temporäre Medien-Datei",
    "checkMediaFile": "Medien-Datei",
    "fileIsTooBig": "Bei dem Upload ist ein Fehler aufgetaucht. Größenbeschränkung wurde überschritten"
  },
  "coreWizard": {
    "youAreWelcome": "Herzlich willkommen bei ProfesSocial!",
    "congratulation": "Herzlichen Glückwunsch!",
    "wizardEnding": "Sie haben ProfesSocial erfolgreich konfiguriert. Ab sofort erhalten Sie zentral bereitgestellte Postings gemäß Ihrer Themen-Abos.",
    "quizEnding": "Der Führerschein wurde erfolgreich abgeschlossen!",
    "toDashboard": "Zum Dashboard",
    "textFacebookConnection": "In den nächsten Minuten können Sie Ihre Facebook- und Instagram-Fanseite mit Hilfe von ProfesSocial professionell und rechtssicher gestalten, sowie verschiedene Themen-Kanäle abonnieren. Wählen Sie nun Ihre Facebook Fanseite aus, die Sie mit ProfesSocial verknüpfen wollen.",
    "textInstagramConnection": "Wählen Sie nun Ihre Instagram Fanseite aus, die Sie mit ProfesSocial verknüpfen wollen.",
    "textTopics": "Nehmen Sie jetzt Ihre persönliche Abo-Einstellung für jeden Themenkanal vor.",
    "textInfo": "Nutzen Sie die Infofelder kurze Beschreibung und ausführliche Beschreibung um Ihre Agentur für Seitenbesucher zu beschreiben.",
    "textProfile": "Hier können sie Ihr Seiten Profil- & Titel Bild ändern. Laden Sie dazu einfach ein neues Bild hoch, oder wählen Sie eine der zahlreichen Vorlagen für Ihre Facebook Seite aus.",
    "back": "Zurück",
    "continue": "Weiter",
    "finish": "Beenden",
    "completeStep": "Schritt abschließen",
    "facebookPageSelection": "Facebook Seitenauswahl",
    "instagramPageSelection": "Instagram Seitenauswahl",
    "facebookPageInfo": "Facebook Seiten-Informationen",
    "titleProfileImages": "Titel- und Profilbild",
    "topicsSelection": "Themenauswahl"

  },
  "coreMessages": {
    "isSuccessfullyCreated": "{{context}} wurde erfolgreich erstellt!",
    "createError": "{{context}} konnte nicht erstellt werden!",
    "isSuccessfullyDeleted": "{{context}} wurde erfolgreich gelöscht!",
    "deleteError": "{{context}} konnte nicht gelöscht werden!",
    "isSuccessfullyUpdated": "{{context}} wurde erfolgreich aktualisiert!",
    "updateError": "{{context}} konnte nicht aktualisiert werden!",
    "areSuccessfullyUpdated": "{{context}} wurden erfolgreich aktualisiert!",
    "updatingError": "{{context}} konnten nicht aktualisiert werden!",
    "isSuccessfullyLoaded": "{{context}} wurde erfolgreich geladen!",
    "loadingError": "{{context}} konnten nicht geladen werden!",
    "loadError": "{{context}} konnte nicht geladen werden!"
   },
  "coreImprint": {
    "explanation": "Es können folgende Platzhalter verwendet werden, die dann automatisch mit den Stammdaten der Agentur ersetzt werden (Platzhalter können mit einem * markiert werden, sie gelten dann als Pflicht-Felder, ohne die das Impressum nicht automatisch veröffentlicht wird): \n [Vorname] [Nachname]* [Email] [Straße] [Plz] [Ort] [Land] [Webseite] [Telefon] [Fax] [Registernummer]",
    "activateEverywhere": "Impressum überall aktualisieren",
    "detailedDescription": "Ausführliche Beschreibung",
    "saveChanges": "Änderungen speichern",
    "onlySave": "Nur speichern",
    "updateAll": "Impressum jetzt auf allen Seiten aktualisieren? (Achtung, vorhandenes Impressum wird überschrieben.)"
  }
}
