{
  "accountsCommon": {
    "submit": "Bestätigen",
    "cancel": "Abbrechen",
    "info": "Weitere Informationen",
    "accessToken": "Access-Token",
    "accountActions": "Aktionen",
    "accessTokenValid": "Access-Token Gültig",
    "admin": "Administratoren",
    "agencyNumber": "Personalnummer",
    "backToOverview": "Zurück zur Übersicht",
    "campaigns": "Kampagnen",
    "commitmentsConfirmed": "Nutzungsbedingungen aktzeptiert",
    "copyUsername": "Benutzername kopieren",
    "createAccount": "Account anlegen",
    "createdAt": "Erstellt am",
    "csvExport": "CSV Export",
    "dailyReach": "tägliche Gesamtreichweite",
    "deleteAllUnlinked": "Alle unverknüpften Seiten löschen",
    "deleteUnlinked": "Unverlinkte Seite löschen",
    "detainee": "Deaktiviert",
    "disconnect": "Trennen",
    "editor": "Redakteure",
    "email": "E-Mail-Adresse",
    "facebookFans": "Fans",
    "failedLoginCount": "Fehlgeschlagene Logins",
    "fanPagesHeadline": "Facebook Fanseiten",
    "fans": "Fans",
    "finishedQuiz": "FB-Führerschein",
    "finishedWizard": "Wizard abgeschlossen",
    "firstName": "Vorname",
    "follower": "Follower",
    "lastAppLogin": "Letzter Applogin",
    "lastLogin": "Letzter Login",
    "lastName": "Nachname",
    "mandator": "Mandant",
    "missingInputError": "Pflichtfeld",
    "name": "Name",
    "noAccounts": "Es gibt keine Accounts mit diesen Eigenschaften.",
    "nonValidate": "kein Datenabgleich",
    "password": "Passwort",
    "language": "Sprache",
    "passwordAgain": "Passwort wiederholen",
    "passwordsError": "Die Passwörter stimmen nicht überein!",
    "emailError": "Die E-Mail ist nicht korrekt",
    "registeredAt": "Registriert am",
    "resetAllUnlinkedPagesWarning": "Achtung, alle Seiten ohne Besitzer werden entfernt und belasten somit nicht mehr Ihre ProfesSocial-Lizenz. Dieser Schritt kann nicht rückgängig gemacht werden. Gelöschte Seiten müssen erneut verbunden werden, um wieder im System verfügbar zu sein.\n\nJetzt alle Seiten löschen?",
    "resetFailedLogin": "Zähler zurücksetzen",
    "role": "Rolle",
    "saveChange": "Änderungen übernehmen",
    "showToken": "Token anzeigen",
    "statusHeadline": "Status",
    "subsidiaries": "Gruppen",
    "subsidiary": "Gruppe",
    "subsidiaryAdmin": "Gruppen-Admin",
    "subsidiaryManagement": "Gruppen",
    "unlinkedPages": "Unverknüpfte Seiten",
    "noUnlinkedPages": "Derzeit gibt es keine unverknüpften Seiten",
    "themeChannels": "Themenkanäle",
    "fbThemeChannels": "Facebook Themenkanäle",
    "instaThemeChannels": "Instagram Themenkanäle",
    "googleThemeChannels": "Google Themenkanäle",
    "connectedFacebookPages": "Einzel-Lizenzen FB",
    "connectedInstagramPages": "Einzel-Lizenzen IG",
    "connectedLinkedInPages": "Einzel-Lizenzen LI",
    "connectedGoogleMyBusinessPages": "Einzel-Lizenzen GMB",
    "connectedGeneral": "Lizenzen insgesamt",
    "time": "Uhr",
    "user": "Benutzer",
    "username": "Nutzername",
    "usernameCopied": "Benutzername kopiert!",
    "validateToken": "Token validieren",
    "userDeletingQuestion": "Soll der Account wirklich gelöscht werden?",
    "newUserCreated": "Neuer Account wurde erfolgreich erstellt!",
    "userChanged": "Account Daten wurden erfolgreich geändert!",
    "userDeleted": "Account wurde erfolgreich gelöscht!",
    "userLoading": "Accounts werden geladen...",
    "userLoaded": "Accounts wurden erfolgreich geladen.",
    "failedLoginsReseting": "Fehlgeschlagene Logins werden zurückgsetzt...",
    "userChanging": "Account Daten werden geändert...",
    "disconnectingFacebook": "Facebookseite wird von User getrennt...",
    "disconnectedFacebook": "Facebookseite erfolgreich von User getrennt!",
    "cannotCreateUser": "Das Anlegen des Nutzers ist fehlgeschlagen. Entweder existiert dieser Nutzername bereits oder ein anderes Problem liegt vor. Versuchen Sie bitte einen anderen Nutzernamen oder wenden Sie sich an den Administrator.",
    "filter": "Filter",
    "networkPageName": "Seiten-Name",
    "networkPageId": "Seiten-ID",
    "active": "Aktiv",
    "firstConnectedAt": "Erster Connect",
    "lastConnectedAt": "Letzter Connect",
    "pageTokenValid": "Seitentoken gültig",
    "urlToPage": "Link zur Seite",
    "network": "Netzwerk",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "google": "Google My Business",
    "de": "Deutsch",
    "en": "Englisch",
    "noAboTopics": "Keine Themenkanäle angelegt.",
    "aboOff": "Deaktiviert",
    "aboStatus": "Abo-Status"
  }
}
